import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Alert,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';

const SubscriptionPlans = [
  {
    id: 'monthly',
    name: 'Monthly Plan',
    price: 999,
    description: 'Access to all features for one month',
    features: [
      'Unlimited product analyses',
      'Priority support',
      'Advanced AI insights',
      'Monthly billing'
    ]
  },
  {
    id: 'yearly',
    name: 'Yearly Plan',
    price: 9990,
    description: 'Access to all features for one year (Save 17%)',
    features: [
      'All Monthly Plan features',
      'Two months free',
      'Premium support',
      'Annual billing'
    ]
  }
];

const Subscription = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [processingPayment, setProcessingPayment] = useState(false);

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  const fetchSubscriptionStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`api/subscription/status`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCurrentSubscription(response.data);
    } catch (err) {
      setError('Failed to fetch subscription status');
    } finally {
      setLoading(false);
    }
  };

  const initializePayment = async (planId) => {
    try {
      setProcessingPayment(true);
      setError('');
      
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `/api/subscribe`,
        { plan_type: planId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Initialize Tinkoff payment widget
      const widget = new window.TinkoffPayWidget({
        terminalKey: response.data.terminal_key,
        paymentId: response.data.payment_id,
        successURL: window.location.origin + '/dashboard',
        failURL: window.location.origin + '/subscription',
        onSuccess: () => {
          fetchSubscriptionStatus();
        },
        onFail: () => {
          setError('Payment failed. Please try again.');
        }
      });

      widget.open();
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to initialize payment');
    } finally {
      setProcessingPayment(false);
    }
  };

  if (loading) {
    return (
      <Container sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {currentSubscription?.status === 'active' && (
        <Alert severity="info" sx={{ mb: 3 }}>
          You have an active subscription until{' '}
          {new Date(currentSubscription.end_date).toLocaleDateString()}
        </Alert>
      )}

      <Typography variant="h4" gutterBottom align="center">
        Subscription Plans
      </Typography>
      
      <Typography variant="body1" paragraph align="center" color="text.secondary">
        Choose the plan that best fits your needs
      </Typography>

      <Grid container spacing={4} sx={{ mt: 2 }}>
        {SubscriptionPlans.map((plan) => (
          <Grid item xs={12} md={6} key={plan.id}>
            <Card raised>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {plan.name}
                </Typography>
                <Typography variant="h4" color="primary" gutterBottom>
                  ₽{(plan.price / 100).toFixed(2)}
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {plan.description}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  {plan.features.map((feature, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      sx={{ mt: 1 }}
                      component="div"
                    >
                      • {feature}
                    </Typography>
                  ))}
                </Box>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center', pb: 3 }}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => initializePayment(plan.id)}
                  disabled={processingPayment}
                >
                  {processingPayment ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Subscribe Now'
                  )}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Subscription;
