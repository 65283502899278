import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const About = () => {
  return (
    <Paper sx={{ p: 3, width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        About
      </Typography>
      <Box>
        <Typography>
          This is the about page content that will be loaded dynamically when clicking the About menu item.
        </Typography>
      </Box>
    </Paper>
  );
};

export default About;
