import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import KeyIcon from "@mui/icons-material/Key";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import axios from "axios";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: 0,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 35,
  height: 35,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "blue",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    background: "green",
  }),
  ...(ownerState.isLastStepAndCompleted && {
    background: "green",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon, validatedStatus } = props;
  const isLastStep = icon === 3;
  const isLastStepAndCompleted = isLastStep && validatedStatus === "validated";

  const icons = {
    1: <KeyIcon />,
    2: <StorefrontIcon />,
    3: <CheckCircleIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, isLastStepAndCompleted }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

const ApiKeyStep = ({
  apiKey,
  setApiKey,
  loading,
  handleSubmit,
  message,
  isApiKeySet,
  validatedStatus,
}) => (
  <Stack spacing={2}>
    <Typography variant="body1">
      Для начала работы необходимо добавить API ключ вашего магазина. Его можно
      получить в настройках вашего аккаунта.
    </Typography>
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="API Ключ"
        variant="outlined"
        type="password"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        required
        disabled={loading}
      />
      {isApiKeySet && (
        <Alert
          severity={validatedStatus === "validated" ? "success" : "info"}
          sx={{ mt: 2 }}
        >
          {validatedStatus === "validated"
            ? "API ключ подтвержден"
            : "Проверка API ключа..."}
          {validatedStatus === "pending" && (
            <CircularProgress size={20} sx={{ ml: 1 }} />
          )}
        </Alert>
      )}
      {message.text && (
        <Alert severity={message.type} sx={{ mt: 2 }}>
          {message.text}
        </Alert>
      )}
      <Button
        type="submit"
        variant="contained"
        disabled={loading}
        sx={{ mt: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : "Проверить и сохранить"}
      </Button>
    </form>
  </Stack>
);

const InitializationStep = () => (
  <Stack spacing={2}>
    <Typography variant="body1">
      Система выполняет проверку и настройку вашего магазина. Это может занять
      некоторое время.
    </Typography>
    <Box sx={{ bgcolor: "background.paper", p: 2, borderRadius: 1 }}>
      <Typography variant="subtitle2" color="text.secondary">
        Выполняются следующие действия:
      </Typography>
      <ul style={{ marginTop: 8, paddingLeft: 20 }}>
        <li>Проверка подключения к API</li>
        <li>Загрузка информации о товарах</li>
        <li>Настройка интеграции</li>
      </ul>
    </Box>
    <Alert severity="info" sx={{ display: "flex", alignItems: "center" }}>
      Пожалуйста, подождите. Идет настройка магазина...
      <CircularProgress size={20} sx={{ ml: 1 }} />
    </Alert>
  </Stack>
);

const CompletionStep = () => (
  <Stack spacing={2}>
    <Typography variant="body1">
      Поздравляем! Ваш магазин успешно подключен и готов к использованию.
    </Typography>
    <Alert severity="success">
      Все необходимые настройки выполнены. Теперь вы можете начать работу с
      системой.
    </Alert>
    <Box sx={{ bgcolor: "background.paper", p: 2, borderRadius: 1 }}>
      <Typography variant="subtitle2" gutterBottom>
        Доступные возможности:
      </Typography>
      <ul style={{ marginTop: 8, paddingLeft: 20 }}>
        <li>Управление товарами</li>
        <li>Аналитика продаж</li>
        <li>Настройка интеграций</li>
      </ul>
    </Box>
  </Stack>
);

export default function VerticalLinearStepper() {
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [isApiKeySet, setIsApiKeySet] = useState(false);
  const [validatedStatus, setValidatedStatus] = useState("not_validated");

  useEffect(() => {
    checkApiKeyStatus();
  }, []);

  useEffect(() => {
    let intervalId;
    if (validatedStatus === "pending") {
      intervalId = setInterval(() => {
        checkApiKeyStatus();
      }, 15000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [validatedStatus]);

  const checkApiKeyStatus = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) return;

      const response = await axios.get("/api/settings/api-key/status", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsApiKeySet(response.data.is_set);
      setValidatedStatus(response.data.validated_status);
    } catch (error) {
      console.error("Error checking API key status:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: "", text: "" });

    try {
      const response = await axios.post(
        "/api/settings/api-key/validate",
        { api_key: apiKey },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.is_valid) {
        setMessage({
          type: "success",
          text: "API ключ успешно проверен и сохранен!",
        });
        setIsApiKeySet(true);
        setApiKey("");
        await checkApiKeyStatus();
      } else {
        setMessage({
          type: "error",
          text: response.data.message || "Неверный API ключ",
        });
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: error.response?.data?.message || "Ошибка проверки API ключа",
      });
    } finally {
      setLoading(false);
    }
  };

  // Determine active step based on state
  const getActiveStep = () => {
    if (!isApiKeySet || validatedStatus === "not_validated") {
      return 0;
    }
    if (validatedStatus === "pending") {
      return 1;
    }
    return 2;
  };

  const activeStep = getActiveStep();

  const steps = [
    {
      label: "Добавление ключа API",
      content: (
        <ApiKeyStep
          apiKey={apiKey}
          setApiKey={setApiKey}
          loading={loading}
          handleSubmit={handleSubmit}
          message={message}
          isApiKeySet={isApiKeySet}
          validatedStatus={validatedStatus}
        />
      ),
    },
    {
      label: "Инициализация магазина",
      content: <InitializationStep />,
    },
    {
      label: "Готов к использованию",
      content: <CompletionStep />,
    },
  ];

  return (
    <Box sx={{ maxWidth: 600 }}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<ColorlibConnector />}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              StepIconProps={{ validatedStatus }}
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Последний шаг</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent sx={{ ml: 2 }}>
              {index === activeStep && step.content}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
