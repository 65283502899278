import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const ExtensionPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box py={4}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Политика конфиденциальности расширения Dialog Master
          </Typography>

          <Box mt={4}>
            <Typography variant="h5" gutterBottom>
              1. Общая информация
            </Typography>
            <Typography paragraph>
              Dialog Master - это расширение для браузера, разработанное для помощи в ответах на вопросы пользователей на маркетплейсе Wildberries. Мы серьезно относимся к защите ваших персональных данных и конфиденциальности.
            </Typography>

            <Typography variant="h5" gutterBottom>
              2. Собираемые данные
            </Typography>
            <Typography variant="h6" gutterBottom>
              2.1. Данные авторизации
            </Typography>
            <Typography component="div">
              <ul>
                <li>Email (используется только для аутентификации)</li>
                <li>Токен доступа (хранится локально в браузере)</li>
              </ul>
            </Typography>

            <Typography variant="h6" gutterBottom>
              2.2. Данные с маркетплейса
            </Typography>
            <Typography component="div">
              <ul>
                <li>Артикул товара</li>
                <li>Текст вопроса пользователя</li>
                <li>Текст ответа на вопрос</li>
              </ul>
            </Typography>

            <Typography variant="h5" gutterBottom>
              3. Использование данных
            </Typography>
            <Typography variant="h6" gutterBottom>
              3.1. Авторизация
            </Typography>
            <Typography component="div">
              <ul>
                <li>Данные авторизации используются исключительно для аутентификации в системе</li>
                <li>Токен доступа хранится локально в браузере</li>
                <li>При выходе из системы все данные авторизации удаляются</li>
              </ul>
            </Typography>

            <Typography variant="h6" gutterBottom>
              3.2. Основной функционал
            </Typography>
            <Typography component="div">
              <ul>
                <li>Артикул товара и текст вопроса передаются на сервер только для генерации ответа</li>
                <li>Данные передаются по защищенному соединению (HTTPS)</li>
                <li>Расширение не сохраняет историю вопросов и ответов локально</li>
              </ul>
            </Typography>

            <Typography variant="h5" gutterBottom>
              4. Хранение данных
            </Typography>
            <Typography variant="h6" gutterBottom>
              4.1. Локальное хранение
            </Typography>
            <Typography component="div">
              <ul>
                <li>В локальном хранилище браузера сохраняется только токен авторизации</li>
                <li>Никакие другие пользовательские данные не сохраняются на устройстве</li>
                <li>Данные локального хранилища можно очистить через настройки браузера</li>
              </ul>
            </Typography>

            <Typography variant="h6" gutterBottom>
              4.2. Серверное хранение
            </Typography>
            <Typography component="div">
              <ul>
                <li>На сервере могут храниться логи запросов для диагностики проблем</li>
                <li>Персональные данные в логах автоматически анонимизируются</li>
                <li>История запросов может использоваться для улучшения качества ответов</li>
              </ul>
            </Typography>

            <Typography variant="h5" gutterBottom>
              5. Передача данных третьим лицам
            </Typography>
            <Typography component="div">
              <ul>
                <li>Мы не продаем и не передаем ваши данные третьим лицам</li>
                <li>Данные не используются для рекламных целей</li>
                <li>Доступ к данным имеют только разработчики для технической поддержки</li>
              </ul>
            </Typography>

            <Typography variant="h5" gutterBottom>
              6. Безопасность
            </Typography>
            <Typography variant="h6" gutterBottom>
              6.1. Технические меры
            </Typography>
            <Typography component="div">
              <ul>
                <li>Все запросы к API осуществляются по защищенному протоколу HTTPS</li>
                <li>Токен авторизации хранится в защищенном хранилище браузера</li>
                <li>Расширение работает только на официальном домене wildberries.ru</li>
              </ul>
            </Typography>

            <Typography variant="h6" gutterBottom>
              6.2. Ограничения доступа
            </Typography>
            <Typography component="div">
              <ul>
                <li>Расширение запрашивает только необходимые разрешения для работы</li>
                <li>Доступ к DOM ограничен только активной вкладкой</li>
                <li>Контент-скрипты активируются только на целевом маркетплейсе</li>
              </ul>
            </Typography>

            <Typography variant="h5" gutterBottom>
              7. Права пользователя
            </Typography>
            <Typography>
              Вы имеете право:
            </Typography>
            <Typography component="div">
              <ul>
                <li>Получить информацию о собираемых данных</li>
                <li>Удалить свои данные через выход из системы</li>
                <li>Отозвать доступ путем удаления расширения</li>
                <li>Запросить экспорт своих данных</li>
              </ul>
            </Typography>

            <Typography variant="h5" gutterBottom>
              8. Изменения политики конфиденциальности
            </Typography>
            <Typography paragraph>
              Мы оставляем за собой право вносить изменения в данную политику конфиденциальности. Все изменения будут публиковаться в этом документе с указанием даты последнего обновления.
            </Typography>

            <Typography variant="h5" gutterBottom>
              9. Контакты
            </Typography>
            <Typography paragraph>
              Если у вас возникли вопросы относительно политики конфиденциальности или обработки данных, пожалуйста, свяжитесь с нами по электронной почте.
            </Typography>

            <Typography variant="body2" color="textSecondary" align="right">
              Дата последнего обновления: {new Date().toLocaleDateString()}
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default ExtensionPolicy;
