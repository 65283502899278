import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const Profile = () => {
  return (
    <Paper sx={{ p: 3, width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      <Box>
        <Typography>
          This is the profile page content that will be loaded dynamically when clicking the Profile menu item.
        </Typography>
      </Box>
    </Paper>
  );
};

export default Profile;
