import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const tiers = [
  {
    title: "Базовый",
    price: "5,000",
    description: [
      "До 1,000 запросов в месяц",
      "Легкая интеграция",
      "Доступ 24/7",
      "Экономия для малого бизнеса",
    ],
    buttonText: "Попробовать бесплатно",
    buttonVariant: "outlined",
    buttonColor: "primary",
  },
  {
    title: "Продвинутый",
    price: "20,000",
    description: [
      "До 10,000 запросов в месяц",
      "Легкая интеграция",
      "Доступ 24/7",
      "Расширенная настройки AI",
      "Стабильная производительность",
      "Приоритетная поддержка",
    ],
    buttonText: "Начать сейчас",
    buttonVariant: "contained",
    buttonColor: "secondary",
  },
  {
    title: "Корпоративный",
    price: "",
    description: [
      "Безлимитные запросы",
      "Индивидуальные настройки",
      "Интеграция с вашей CRM / Базы знаний",
      "Приоритетная поддержка",
      "Phone & email support",
    ],
    buttonText: "Связаться с нами",
    buttonVariant: "outlined",
    buttonColor: "primary",
  },
];

export default function Pricing() {
  return (
    <Box
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography
            component="h2"
            variant="h4"
            gutterBottom
            sx={{ color: "text.primary" }}
          >
            Доступные планы для автоматизации
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            Выберите подходящее решение
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
          sx={{ alignItems: "center", justifyContent: "center", width: "100%" }}
        >
          {tiers.map((tier) => (
            <Grid
              size={{
                xs: 12,
                sm: tier.title === "Корпоративный" ? 12 : 6,
                md: 4,
              }}
              key={tier.title}
            >
              <Card
                sx={[
                  {
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                  },
                  tier.title === "Продвинутый" &&
                    ((theme) => ({
                      border: "none",
                      background:
                        "radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))",
                      boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                      ...theme.applyStyles("dark", {
                        background:
                          "radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))",
                        boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                      }),
                    })),
                ]}
              >
                <CardContent>
                  <Box
                    sx={[
                      {
                        mb: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                      },
                      tier.title === "Продвинутый"
                        ? { color: "grey.100" }
                        : { color: "" },
                    ]}
                  >
                    <Typography component="h3" variant="h6">
                      {tier.title}
                    </Typography>
                    {tier.title === "Продвинутый" && (
                      <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                    )}
                  </Box>
                  {tier.price && (
                    <Box
                      sx={[
                        {
                          display: "flex",
                          alignItems: "baseline",
                        },
                        tier.title === "Продвинутый"
                          ? { color: "grey.50" }
                          : { color: null },
                      ]}
                    >
                      <Typography component="h3" variant="h4">
                        {tier.price}
                      </Typography>
                      <Typography component="h3" variant="h6">
                        &nbsp; руб/мес
                      </Typography>
                    </Box>
                  )}
                  <Divider
                    sx={{ my: 2, opacity: 0.8, borderColor: "divider" }}
                  />
                  {tier.description.map((line) => (
                    <Box
                      key={line}
                      sx={{
                        py: 1,
                        display: "flex",
                        gap: 1.5,
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={[
                          {
                            width: 20,
                          },
                          tier.title === "Продвинутый"
                            ? { color: "primary.light" }
                            : { color: "primary.main" },
                        ]}
                      />
                      <Typography
                        variant="subtitle2"
                        component={"span"}
                        sx={[
                          tier.title === "Продвинутый"
                            ? { color: "grey.50" }
                            : { color: null },
                        ]}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color={tier.buttonColor}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
