import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import SitemarkIcon from "./SitemarkIcon";

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
      {"© "}
      <Link color="text.secondary" href="/">
        Diana AI
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      id="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <SitemarkIcon />
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Продукт
          </Typography>
          <Link color="text.secondary" variant="body2" href="#features">
            Особенности
          </Link>
          <Link color="text.secondary" variant="body2" href="#latest">
            Сравнение
          </Link>
          <Link color="text.secondary" variant="body2" href="#highlights">
            Выгоды
          </Link>
          <Link color="text.secondary" variant="body2" href="#pricing">
            Цены
          </Link>
          <Link color="text.secondary" variant="body2" href="#faq">
            Вопросы
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Компания
          </Typography>
          <Link color="text.secondary" variant="body2" href="/about">
            О нас
          </Link>
          <Link color="text.secondary" variant="body2" href="/news">
            Новости
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          {/* <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Документы
          </Typography>
          <Link color="text.secondary" variant="body2" href="/terms">
            Условия использования
          </Link>
          <Link color="text.secondary" variant="body2" href="/privacy">
            Конфиденциальность
          </Link>
          <Link color="text.secondary" variant="body2" href="/contact">
            Контакты
          </Link> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link color="text.secondary" variant="body2" href="/privacy">
            Политика конфиденциальности
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          {/* <Link color="text.secondary" variant="body2" href="/terms">
            Условия использования
          </Link> */}
          {/* <Copyright /> */}
        </div>
      </Box>
    </Container>
  );
}
