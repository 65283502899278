import * as React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const articleInfo = [
  {
    question: "Растворятся ли в специальной жидкости? ( для гелевых типсс)",
    answer: "Не знаем, не пробовали.",
    ai_answer:
      "Здравствуйте! Спасибо за ваш вопрос. К сожалению, мы не знаем, растворяются ли эти накладные ногти в специальной жидкости для гелевых типс, так как таких испытаний не проводилось. Если у вас возникнут дополнительные вопросы, буду рад помочь! Хорошего дня!",
  },
  {
    question:
      "Менс формула и зма можно принимать вместе ? Нужно принимать по олной таблетке и той и той добавки ?",
    answer:
      "Добрый день, да ожно принимать вместе по 1 таблетки мужских витаминов и зма по 1 капсуле.",
    ai_answer:
      "Здравствуйте! Спасибо за ваш вопрос. Да, мужские витамины и ЗМА можно принимать вместе. Рекомендуется принимать по 1 таблетке мужских витаминов и 1 капсуле ЗМА во время еды, как указано в инструкции к продукту. Если у вас есть дополнительные вопросы, обращайтесь! Всего доброго.",
  },
  {
    question: "подскажите ,какой мощности виброплатформа?",
    answer:
      "Здравствуйте! Благодарим за интерес, проявленный к нашему бренду! Программ- 5, скоростей всего- 99, амплитуда вибраций-0-30 Гц, допустимый вес - до 150 кг., мощность - 200W. Будем рады видеть Вас в качестве нашего покупателя! ",
    ai_answer:
      "Здравствуйте! Мощность виброплатформы — 200 Вт. Максимальный вес пользователя — до 150 кг. Будем рады вашему заказу! 😊",
  },
  {
    question: "можно ли уменьшить стельки под ногу ребенка до 33 размера?",
    answer: "Здравствуйте,да",
    ai_answer:
      "Здравствуйте! Спасибо за ваш вопрос. Да, стельки можно уменьшить под ногу ребенка до 33 размера. Если у вас возникнут дополнительные вопросы, пожалуйста, обращайтесь!",
  },
];

function BasicCard({ article, ai_answer }) {
  const content = article || ai_answer;
  const isAiAnswer = Boolean(ai_answer);

  const cardStyle = isAiAnswer
    ? {
        background: "linear-gradient(to right bottom, #ffffff, #f8f9ff)",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: 2,
        "&:hover": {
          boxShadow: "0 6px 16px rgba(0, 0, 0, 0.15)",
        },
        transition: "all 0.3s ease",
      }
    : {
        bgcolor: "#f5f5f5",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
        borderRadius: 2,
      };

  const titleStyle = isAiAnswer
    ? {
        color: "#2196f3",
        fontWeight: 600,
        mb: 0.5,
        fontSize: "1.1rem",
      }
    : {
        color: "text.secondary",
        mb: 0.5,
      };

  const contentStyle = isAiAnswer
    ? {
        color: "#2c3e50",
        lineHeight: 1.6,
      }
    : {
        color: "#666666",
      };

  return (
    <Card sx={cardStyle}>
      <CardContent sx={{ py: 1.5 }}>
        <Typography sx={titleStyle}>
          {isAiAnswer ? "Ответ Дианы" : "Ответ продавца"}
        </Typography>
        <Typography variant="body2" sx={contentStyle}>
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}

const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const TitleTypography = styled(Typography)(({ theme }) => ({
  position: "relative",
  textDecoration: "none",
  "&:hover": { cursor: "pointer" },
  "& .arrow": {
    visibility: "hidden",
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  "&:hover .arrow": {
    visibility: "visible",
    opacity: 0.7,
  },
  "&:focus-visible": {
    outline: "3px solid",
    outlineColor: "hsla(210, 98%, 48%, 0.5)",
    outlineOffset: "3px",
    borderRadius: "8px",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    width: 0,
    height: "1px",
    bottom: 0,
    left: 0,
    backgroundColor: (theme.vars || theme).palette.text.primary,
    opacity: 0.3,
    transition: "width 0.3s ease, opacity 0.3s ease",
  },
  "&:hover::before": {
    width: "100%",
  },
}));

function Author({ authors }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        mb: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Avatar key="0" alt="P" src="" sx={{ width: 48, height: 48 }} />
        <TitleTypography variant="h6">
          Покупатель Wildberries
          <NavigateNextRoundedIcon
            className="arrow"
            sx={{ fontSize: "1rem" }}
          />
        </TitleTypography>
      </Box>
    </Box>
  );
}

Author.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default function Latest() {
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);

  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  return (
    <Box id="latest" sx={{ mt: 10 }}>
      <Container
        sx={{
          position: "relative",
        }}
      >
        <div>
          <Typography
            component="h2"
            variant="h4"
            gutterBottom
            sx={{ color: "text.primary" }}
          >
            Кто отвечает лучше?
          </Typography>
          <Grid container spacing={8} columns={12} sx={{ my: 4 }}>
            {articleInfo.map((article, index) => (
              <Grid key={index} size={{ xs: 12, sm: 6 }}>
                <Card
                  sx={{
                    p: 3,
                    height: "100%",
                    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.08)",
                    "&:hover": {
                      boxShadow: "0 12px 28px rgba(0, 0, 0, 0.12)",
                    },
                    transition: "box-shadow 0.3s ease",
                  }}
                >
                  <Author authors={article.authors} />
                  <StyledTypography
                    variant="body2"
                    color="text.primary"
                    gutterBottom
                    sx={{ mb: 3, ml: 7 }}
                  >
                    {article.question}
                  </StyledTypography>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <BasicCard article={article.answer} />
                    <BasicCard ai_answer={article.ai_answer} />
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </Box>
  );
}
