import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// Components
import Dashboard from './components/dashboard/Dashboard';
import { StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import SignIn from './components/sign-in/SignIn';
import SignUp from './components/sign-up/SignUp';
import MarketingPage from './components/main/MarketingPage';
import ExtensionPolicy from './components/policy/ExtensionPolicy';
import PrivateRoute from './components/routing/PrivateRoute';
import AppTheme from './components/shared-theme/AppTheme';

function App() {
  return (
    <Router>
      <StyledEngineProvider injectFirst>
        <AppTheme>
          <CssBaseline />
          <div className="App">
            <Routes>
              <Route path="/" element={<MarketingPage />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/extension-policy" element={<ExtensionPolicy />} />
              <Route
                path="/*"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </AppTheme>
      </StyledEngineProvider>
    </Router>
  );
}

export default App;
