import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const ProductAnalysis = () => {
  const [analysis, setAnalysis] = useState(null);
  const [error, setError] = useState('');

  const validationSchema = Yup.object({
    product_id: Yup.string()
      .required('Product ID is required'),
    originalQuestion: Yup.string()
      .required('Question is required')
      .min(10, 'Question should be at least 10 characters')
      .max(500, 'Question should not exceed 500 characters'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `/api/analyze`,
        {
          product_id: values.product_id,
          question: values.originalQuestion,
        },
        { headers }
      );

      setAnalysis(response.data);
      setError('');
      resetForm();
    } catch (err) {
      setError(
        err.response?.data?.detail ||
        'An error occurred while analyzing the product'
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Product Analysis
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Enter a product ID and ask a question about the product.
          Our AI will analyze the product and provide insights.
        </Typography>
        <Typography id='myQuestion'> Есть ли средство для снятия ногтей в комплекте? </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Formik
          initialValues={{
            product_id: '',
            originalQuestion: '',
            answerText: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <TextField
                fullWidth
                id="product_id"
                name="product_id"
                label="Product ID"
                value={values.product_id}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.product_id && Boolean(errors.product_id)}
                helperText={touched.product_id && errors.product_id}
                margin="normal"
                placeholder="Enter product ID"
              />

              <TextField
                fullWidth
                id="originalQuestion"
                name="originalQuestion"
                label="Your Question"
                value={values.originalQuestion}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.originalQuestion && Boolean(errors.originalQuestion)}
                helperText={touched.originalQuestion && errors.originalQuestion}
                margin="normal"
                multiline
                rows={3}
                placeholder="Ask a question about the product..."
              />

              <textarea
                id="answerText"
                name="answerText"
                placeholder="Текст ответа"
                value={values.answerText}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{
                  width: '100%',
                  minHeight: '100px',
                  padding: '10px',
                  marginTop: '16px',
                  marginBottom: '8px',
                  borderColor: touched.answerText && errors.answerText ? '#d32f2f' : '#ccc',
                  borderRadius: '4px',
                  fontFamily: 'inherit',
                  fontSize: 'inherit',
                }}
                readOnly
              />

              <Box sx={{ mt: 3, mb: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? <CircularProgress size={20} /> : null
                  }
                >
                  {isSubmitting ? 'Analyzing...' : 'Analyze Product'}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>

        {analysis && (
          <Box sx={{ mt: 4 }}>
            <Divider sx={{ mb: 3 }} />
            <Typography variant="h6" gutterBottom>
              Analysis Results
            </Typography>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Product ID
                </Typography>
                <Typography variant="body2" paragraph>
                  {analysis.product_id}
                </Typography>

                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Question
                </Typography>
                <Typography variant="body2" paragraph>
                  {analysis.question}
                </Typography>

                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  AI Response
                </Typography>
                <Typography variant="body1" component="div">
                  {analysis.response}
                </Typography>

                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mt: 2, display: 'block' }}
                >
                  Analysis completed at:{' '}
                  {new Date(analysis.created_at).toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default ProductAnalysis;
