import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import MuiChip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";

import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import EdgesensorHighRoundedIcon from "@mui/icons-material/EdgesensorHighRounded";
import ViewQuiltRoundedIcon from "@mui/icons-material/ViewQuiltRounded";

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: "Ответы без ожидания",
    description:
      "Ассистент мгновенно отвечает на вопросы клиентов в любое время суток, повышая их лояльность и сокращая нагрузку на поддержку..",
    imageLight: `url(${process.env.PUBLIC_URL || ""}/main/main-feature-1.jpg)`,
    imageDark: `url(${process.env.PUBLIC_URL || ""}/main/main-feature-1.jpg)`,
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: "Легкое подключение",
    description:
      "Удобное подключение к маркетплейсам без необходимости сложной настройки или изменений в вашем бизнесе.",
    imageLight: `url(${process.env.PUBLIC_URL || ""}/main/main-feature-2.jpg)`,
    imageDark: `url(${process.env.PUBLIC_URL || ""}/main/main-feature-2.jpg)`,
  },
  {
    icon: <DevicesRoundedIcon />,
    title: "Искусственный интеллект для продаж",
    description:
      "Ассистент использует AI для точных ответов и рекомендаций, помогая клиентам найти нужный товар и совершить покупку.",
    imageLight: `url(${process.env.PUBLIC_URL || ""}/main/main-feature-3.jpg)`,
    imageDark: `url(${process.env.PUBLIC_URL || ""}/main/main-feature-3.jpg)`,
  },
];

const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          "linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))",
        color: "hsl(0, 0%, 100%)",
        borderColor: (theme.vars || theme).palette.primary.light,
        "& .MuiChip-label": {
          color: "hsl(0, 0%, 100%)",
        },
        ...theme.applyStyles("dark", {
          borderColor: (theme.vars || theme).palette.primary.dark,
        }),
      },
    },
  ],
}));

function MobileLayout({ selectedItemIndex, handleItemClick, selectedFeature }) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: "flex", sm: "none" },
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, overflow: "auto" }}>
        {items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={() => handleItemClick(index)}
            selected={selectedItemIndex === index}
          />
        ))}
      </Box>
      <Card variant="outlined">
        <Box
          sx={(theme) => ({
            mb: 2,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            minHeight: 280,
            backgroundImage: "var(--items-imageLight)",
            ...theme.applyStyles("dark", {
              backgroundImage: "var(--items-imageDark)",
            }),
          })}
          style={
            items[selectedItemIndex]
              ? {
                  "--items-imageLight": items[selectedItemIndex].imageLight,
                  "--items-imageDark": items[selectedItemIndex].imageDark,
                }
              : {}
          }
        />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: "text.primary", fontWeight: "medium" }}
          >
            {selectedFeature.title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1.5 }}>
            {selectedFeature.description}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

MobileLayout.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  selectedFeature: PropTypes.shape({
    description: PropTypes.string.isRequired,
    icon: PropTypes.element,
    imageDark: PropTypes.string.isRequired,
    imageLight: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  selectedItemIndex: PropTypes.number.isRequired,
};

export { MobileLayout };

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Box
      id="features"
      sx={{
        scrollMarginTop: "100px", // Added scroll margin to offset fixed AppBar
      }}
    >
      <Container sx={{ py: { xs: 12, sm: 16 } }}>
        <Box sx={{ width: { sm: "100%", md: "60%" } }}>
          <Typography
            component="h2"
            variant="h4"
            gutterBottom
            sx={{ color: "text.primary" }}
          >
            Ключевые особенности
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "text.secondary", mb: { xs: 2, sm: 4 } }}
          >
            Ознакомьтесь с основными возможностями нашего AI-помощника. От
            безупречной интеграции с маркетплейсами до точных ответов — узнайте,
            как наш продукт улучшает поддержку клиентов и способствует росту
            вашего бизнеса.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row-reverse" },
            gap: 2,
          }}
        >
          <div>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                flexDirection: "column",
                gap: 2,
                height: "100%",
              }}
            >
              {items.map(({ icon, title, description }, index) => (
                <Box
                  key={index}
                  component={Button}
                  onClick={() => handleItemClick(index)}
                  sx={[
                    (theme) => ({
                      p: 2,
                      height: "100%",
                      width: "100%",
                      "&:hover": {
                        backgroundColor: (theme.vars || theme).palette.action
                          .hover,
                      },
                    }),
                    selectedItemIndex === index && {
                      backgroundColor: "action.selected",
                    },
                  ]}
                >
                  <Box
                    sx={[
                      {
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        gap: 1,
                        textAlign: "left",
                        textTransform: "none",
                        color: "text.secondary",
                      },
                      selectedItemIndex === index && {
                        color: "text.primary",
                      },
                    ]}
                  >
                    {icon}

                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="body2">{description}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <MobileLayout
              selectedItemIndex={selectedItemIndex}
              handleItemClick={handleItemClick}
              selectedFeature={selectedFeature}
            />
          </div>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              width: { xs: "100%", md: "70%" },
              height: "var(--items-image-height)",
            }}
          >
            <Card
              variant="outlined"
              sx={{
                height: "100%",
                width: "100%",
                display: { xs: "none", sm: "flex" },
                pointerEvents: "none",
                p: 0,
                overflow: "hidden",
              }}
            >
              <Box
                sx={(theme) => ({
                  width: "100%",
                  height: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: "var(--items-imageLight)",
                  m: 0,
                  ...theme.applyStyles("dark", {
                    backgroundImage: "var(--items-imageDark)",
                  }),
                })}
                style={
                  items[selectedItemIndex]
                    ? {
                        "--items-imageLight":
                          items[selectedItemIndex].imageLight,
                        "--items-imageDark": items[selectedItemIndex].imageDark,
                      }
                    : {}
                }
              />
            </Card>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
