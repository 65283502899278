import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";

const StyledBox = styled("div")(({ theme }) => ({
  aspectRatio: "832/1216",
  width: "100%",
  maxWidth: "400px",
  maxHeight: "600px",
  borderRadius: (theme.vars || theme).shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "1px solid",
  borderColor: (theme.vars || theme).palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundImage: `url(${process.env.PUBLIC_URL || ""}/main/Diana-ai.jpg)`,
  outlineColor: "hsla(220, 20%, 42%, 0.1)",
  backgroundSize: "100% 100%",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));

export default function Hero() {
  return (
    <Box
      sx={{
        color: "white",
        bgcolor: "grey.900",
      }}
    >
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(224deg 6.67% 55.88%), #212121c7)",
        })}
      >
        <Container
          sx={{
            position: "relative",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              pt: { xs: 14, sm: 20 },
              pb: { xs: 8, sm: 12 },
              ml: { xs: 0, sm: 5, mb: 0 },
              mr: { xs: 0, sm: 5, mb: 0 },
            }}
          >
            <Grid
              size={{ xs: 12, sm: 12, md: 7 }}
              sx={{
                mb: { xs: 15, sm: 0, md: 0 },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: "clamp(2rem, 5vw, 3rem)",
                  mb: 4,
                }}
              >
                Привет,&nbsp;я&nbsp;Диана!
              </Typography>
              <Stack direction="column" spacing={2} useFlexGap>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "white",
                    width: { sm: "100%", md: "100%" },
                    fontSize: "clamp(1rem, 2vw, 1.2rem)",
                  }}
                >
                  {" "}
                  Ваш умный AI-помощник для поддержки клиентов.
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "white",
                    width: { sm: "100%", md: "100%" },
                    fontSize: "clamp(1rem, 2vw, 1.2rem)",
                  }}
                >
                  Отвечайте на вопросы покупателей быстро и точно с помощью AI
                  на маркетплейсах Ozon и Wildberries.
                </Typography>
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={1}
                useFlexGap
                sx={{ pt: 2, width: { xs: "100%", sm: "350px" } }}
              >
                <Button
                  href="/register"
                  variant="outlined"
                  size="medium"
                  sx={{ minWidth: "fit-content", backgroundColor: "white" }}
                >
                  Подключить сейчас
                </Button>
              </Stack>
            </Grid>
            <Grid
              size={{ xs: 12, sm: 12, md: 5 }}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "center", md: "right" },
                alignItems: "center",
                height: { xs: "400px", sm: "500px", md: "600px" },
                pl: { xs: 0, sm: 0, md: 2 },
                mb: { xs: 12, sm: 0, md: 0 },
                mt: { xs: 0, sm: 8, md: 0 },
              }}
            >
              <StyledBox id="image" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
