import React from "react";
import { Container, Paper, Typography } from "@mui/material";
import CustomizedSteppers from "../settings/Timline";

const Settings = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>
        <CustomizedSteppers />
      </Paper>
    </Container>
  );
};

export default Settings;
