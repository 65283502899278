import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 35, width: 100, mr: 0, filter: "invert(1)" }}>
      <svg
        width={40}
        height={40}
        viewBox="0 0 1500 533"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        space="preserve"
        data-id="lg_qkPBJwPfVMnN90MtRU"
        data-version="1"
      >
        <g data-padding="20">
          <g transform="translate(10 10)scale(.96248)">
            <rect
              width="448.284"
              height="448.284"
              x="85.816"
              y="-336.327"
              fill="none"
              stroke="#fff"
              stroke-width="22.5"
              paint-order="stroke"
              rx="0"
              ry="0"
              transform="translate(400 400)scale(1.19)"
            />
            <rect
              width="248.433"
              height="379.68"
              x="244.633"
              y="-323.34"
              fill="none"
              rx="0"
              ry="0"
              transform="translate(400.5 400.14)"
            />
            <path
              fill="#fff"
              stroke="#fff"
              stroke-width="7.32"
              d="M718.5 183.49v148.52h40.99q15.79 0 29.4-5.55 13.61-5.54 23.86-15.62t15.79-23.52 5.54-29.23q0-15.8-5.71-29.4-5.71-13.61-15.79-23.69t-23.69-15.79q-13.61-5.72-29.4-5.72Zm41.66 191.86h-90.38v-235.2h90.38q26.55 0 49.06 8.9 22.51 8.91 39.31 25.04 16.8 16.12 26.38 37.63 9.57 21.5 9.57 46.37 0 25.2-9.4 46.36-9.41 21.17-26.38 37.13t-39.48 24.87q-22.51 8.9-49.06 8.9Z"
              paint-order="stroke"
            />
          </g>
          <path
            fill="transparent"
            stroke="transparent"
            d="M483.5 0h533v533h-533z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
